<template>
  <div class="box" id="ftServe">
    <div class="top">
      <div class="title">丰富、成熟、专业的产品服务</div>
      <div class="sub-title">
        <router-link to="/product/platform">了解更多</router-link>
      </div>
    </div>
    <div class="center">
      <div class="left">
        <div
          v-for="(item, index) in list"
          :key="item.title"
          :class="{ 'item-box': true, active: index === currentIndex }"
          @click="onClick(index)"
          v-on:mouseenter="onMouseenter(index)"
        >
          <div class="title">{{ item.title }}</div>
          <div class="sub-title">{{ item.subTitle }}</div>
          <div class="img-box">
            <img v-lazy="item.imgSrc" alt="" loading="lazy" />
          </div>
        </div>
        <div
          :style="{
            'text-align': 'center',
            'margin-top': '46px',
            padding: '0px 20px',
            '--hoverBgColor': '#0F1490',
          }"
        >
          <FtButton
            @onClick="onUseBtnClick"
            style="
              width: 100%;
              height: 40px;
              background-color: #1c55fb;
              border: none;
              font-size: 16px;
            "
          >
            立即试用
          </FtButton>
        </div>
      </div>
      <div class="right">
        <Dropdown
          class="custom-dropdown"
          v-model="currentIndex"
          optionValue="code"
          optionLabel="name"
          :options="
            list.map((item, index) => {
              return { name: item.title, code: index };
            })
          "
          placeholder="Select a Car"
        />
        <img
          v-show="!Array.isArray(list[currentIndex].imgSrc)"
          v-lazy="list[currentIndex].imgSrc"
          alt=""
        />
        <div
          v-show="Array.isArray(list[currentIndex].imgSrc)"
          style="position: relative"
        >
          <div class="swiper-button-prev"></div>
          <swiper :options="swiperOptions">
            <swiper-slide
              v-for="(src, index) in list[currentIndex].imgSrc"
              :key="index"
            >
              <img :src="src" alt="" />
            </swiper-slide>
          </swiper>
          <div class="swiper-pagination"></div>
          <div class="swiper-button-next"></div>
        </div>
        <div class="use-btn">
          <FtButton
            @onClick="onUseBtnClick"
            style="
              width: 100%;
              height: 2.5rem;
              background-color: #1c55fb;
              border: none;
              font-size: 1rem;
            "
          >
            立即试用
          </FtButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from "primevue/dropdown";
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import FtButton from "../../components/FtButton.vue";
import mobile from "../../assets/img/home/server/移动智能金融终端.jpg";
import client from "../../assets/img/home/server/非凸智能交易终端.jpg";
import product1 from "../../assets/img/home/server/产品服务-智能算法单.jpg";
import product2 from "../../assets/img/home/server/产品服务-底仓增强.jpg";
import product3 from "../../assets/img/home/server/产品服务-短线雷达.jpg";
import product4 from '../../assets/img/home/server/智能助手.jpg'
import product5 from '../../assets/img/home/server/智选ETF.jpg'

export default {
  name: "Server",
  components: {
    FtButton,
    Swiper,
    SwiperSlide,
    Dropdown,
  },
  data() {
    return {
      list: [
        {
          title: "最新产品",
          subTitle: "智能助手、智投ETF、短线雷达、智能算法单、底仓增强产品等",
          imgSrc: [product5,product4,product3, product1, product2],
        },
        {
          title: "智能交易终端",
          subTitle: "全方位构建数智交易一体化解决方案",
          imgSrc: client,
        },
        {
          title: "移动智能金融终端",
          subTitle: "实现便捷的算法签约与交易管理",
          imgSrc: mobile,
        },
      ],
      currentIndex: 0,
      swiperOptions: {
        // loop: true,
        speed: 500,
        // {
        //   delay: 3000,
        //   disableOnInteraction: false,
        //   waitForTransition: true,
        // }
        autoplay: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          dynamicMainBullets: 3,
          clickable: true,
        },
      },
    };
  },
  methods: {
    onClick: function (index) {
      this.currentIndex = index;
    },
    onUseBtnClick: function () {
      window.open("https://wj.qq.com/s2/11823137/a41b/");
    },

    onMouseenter: function (index) { 
      this.currentIndex = index;
    }
  },
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  background-color: #242424;
  flex-direction: column;
  padding: 72px 0px;
  justify-content: center;
  text-align: center;

  .top {
    margin: 0 auto;
    .title {
      color: #fff;
      font-size: 28px;
      font-style: normal;
      font-weight: 600;
      line-height: 34px;
      margin-bottom: 8px;
    }

    .sub-title {
      a {
        text-decoration: none;
      }
      color: #1c55fb;
      font-size: 16px;
      font-style: normal;
      font-weight: 200;
      line-height: 24px;
    }
  }

  .center {
    margin: 0 auto;
    margin-top: 44px;
    display: flex;
    width: 1360px;
    gap: 8px;
    @media screen and (max-width: 1360px) {
      padding: 0px 20px;
    }
    .left {
      padding: 32px 0px;
      text-align: left;
      background-color: #2f2f2f;
      width: 23.8%;
      .item-box {
        cursor: pointer;
        padding: 40px 20px 40px 18px;
        transition: all 0.5s;
        border-left: 2px solid transparent;
        .title {
          color: #fff;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }
        .sub-title {
          transition: all 0.5s;
          margin-top: 4px;
          color: #fff;
          font-size: 14px;
          font-weight: 300;
          line-height: 24px;
          height: 0px;
          overflow: hidden;
        }

        .img-box {
          display: none;
        }
      }

      .active {
        border-color: #fff;
        background-color: #1c55fb;
        padding-top: 20px;
        padding-left: 18px;
        padding-bottom: 20px;
        .sub-title {
          height: 48px;
          overflow: hidden;
          display: -webkit-box;
          text-overflow: ellipsis;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }

    .right {
      background-color: #2f2f2f;
      width: 76.2%;
      max-height: 480px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px;

      .use-btn{
        display: none;
      }

      .custom-dropdown {
        display: none;
      }

      div[class~="swiper-slide"] {
        width: 100% !important;
      }

      .swiper-pagination {
        position: absolute;
        display: none !important;
        left: 20px;
        bottom: 12px;
        display: flex;
        gap: 8px;

        :deep(.swiper-pagination-bullet) {
          width: 35px;
          height: 6px;
          border-radius: 999px;
          background-color: rgba(28, 85, 251, 0.80);;
          display: inline-block;
        }
        /* 当前选中的小圆点样式（可自定义颜色等突出显示） */
        :deep(.swiper-pagination-bullet-active) {
          background-color: #1c55fb;
        }
      }

      .swiper-button-prev,
      .swiper-button-next {
        width: 40px;
        height: 40px;
        border-radius: 999px;
        background-color: #ffffff33;
        &::after {
          font-size: 18px;
          color: #fff;
        }
      }

      .swiper-button-prev {
        left: 32px;
      }
      .swiper-button-next {
        right: 32px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  @media screen and (max-width: 1360px) {
    .center {
      width: 100%;
    }
  }

  @media screen and (max-width: map-get($--ft-width, mobile)) {
    padding: 2rem 0;
    .top {
      .title {
        font-size: 1.25rem;
        line-height: 1.625rem;
        margin-bottom: 0.12rem;
      }
      .sub-title {
        font-size: 0.75rem;
        line-height: 1.25rem;
      }
    }
    .center {
      margin-top: 0.5rem;
      display: block;
      padding: 0.75rem;

      .left {
        display: none;
      }
      .right {
        width: 100%;
        max-height: unset;
        padding: 0;
        background-color: unset;
        display: block;
        color: #fff;
        .use-btn {
          display: block;
          text-align: center;
          margin-top: 0.75rem;
          --hoverBgColor: #0f1490;
        }

        .swiper-pagination {
          display: flex !important;
          width: 4.9375rem;
          height: 0.375rem;
          align-items: center;
          gap: 0.5rem;
          left: 50%;
          transform: translateX(-50%);
          bottom: 0.63rem;
        }
        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }

        .custom-dropdown {
          display: flex;
          background-color: #1c55fb;
          border: none;
          box-shadow: none;
          width: 100%;
          margin-bottom: 0.75rem;

          :deep(.p-inputtext),
          :deep(.p-dropdown-trigger) {
            color: #fff !important;
          }
        }

        img {
          width: 100%;
        }
      }
    }
  }
}
</style>
