<template>
  <div class="box" id="ftAdvantages" v-lazy:background-image="boxBackGroundImg">
    <div class="title">优秀团队，打造极致竞争力</div>
    <div class="top">
      <div v-for="item in teamText" :key="item.title" class="top-item">
        <div class="top-item-title">{{ item.title }}</div>
        <div class="top-item-subTitle">{{ item.subTitle }}</div>
      </div>
    </div>
    <div class="bottom">
      <div class="tab-advantages">
        <div
          :class="{ item: true, active: currentIndex === 0 }"
          @click="handleClick(0)"
        >
          核心团队
        </div>
        <div
          :class="{ item: true, active: currentIndex === 1 }"
          @click="handleClick(1)"
        >
          核心优势
        </div>
        <div
          :class="{ item: true, active: currentIndex === 2 }"
          @click="handleClick(2)"
        >
          资质荣誉
        </div>
      </div>
      <div class="tab-content">
        <div class="team">
          <div class="left">
            <div v-for="item in teamText" :key="item.title" class="item">
              <div class="item-title">{{ item.title }}</div>
              <div class="item-subTitle">{{ item.subTitle }}</div>
            </div>
          </div>
          <div class="right">
            <div v-show="currentIndex === 0" class="tab-team-client">
              <div style="display: flex; flex-wrap: nowrap; height: 139px">
                <div
                  v-for="item in teamMember.slice(0, teamMember.length / 2)"
                  :key="item.name"
                  class="item"
                >
                  <div style="margin-top: auto; margin-bottom: auto">
                    <div class="item-name">{{ item.name }}</div>
                    <div class="item-subTitle">{{ item.subTitle }}</div>
                  </div>
                  <div style="margin-top: auto; margin-bottom: auto">
                    <div class="item-description">
                      <div
                        v-for="description in item.description"
                        :key="description"
                        class="description"
                      >
                        {{ description }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex; flex-wrap: nowrap; height: 139px">
                <div
                  v-for="item in teamMember.slice(teamMember.length / 2)"
                  :key="item.name"
                  class="item"
                >
                  <div style="margin-top: auto; margin-bottom: auto">
                    <div class="item-name">{{ item.name }}</div>
                    <div class="item-subTitle">{{ item.subTitle }}</div>
                  </div>
                  <div style="margin-top: auto; margin-bottom: auto">
                    <div class="item-description">
                      <div
                        v-for="description in item.description"
                        :key="description"
                        class="description"
                      >
                        {{ description }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-show="currentIndex === 0" class="tab-team-mobile">
              <swiper :options="swiperOptions">
                <swiper-slide v-for="item in [0, 2, 4]" :key="item + 'team'">
                  <div
                    :class="{
                      'swiper-item': true,
                      'swiper-item-active': currentTeamMember === item,
                    }"
                    @click="onTeamMemberClick(item)"
                  >
                    <div style="margin-top: auto; margin-bottom: auto">
                      <div class="mobile-item-name">
                        {{ teamMember[item].name }}
                      </div>
                      <div class="mobile-item-subTitle">
                        {{ teamMember[item].subTitle }}
                      </div>
                    </div>
                    <div class="mobile-item-description">
                      <div
                        v-for="description in teamMember[item].description"
                        :key="description"
                        class="mobile-description"
                      >
                        {{ description }}
                      </div>
                    </div>
                  </div>
                  <div
                    :class="{
                      'swiper-item': true,
                      'swiper-item-active': currentTeamMember === item + 1,
                    }"
                    @click="onTeamMemberClick(item + 1)"
                  >
                    <div style="margin-top: auto; margin-bottom: auto">
                      <div class="mobile-item-name">
                        {{ teamMember[item + 1].name }}
                      </div>
                      <div class="mobile-item-subTitle">
                        {{ teamMember[item + 1].subTitle }}
                      </div>
                    </div>
                    <div class="mobile-item-description">
                      <div
                        v-for="description in teamMember[item + 1].description"
                        :key="description"
                        class="mobile-description"
                      >
                        {{ description }}
                      </div>
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="swiper-pagination-team"></div>
            </div>
            <div
              class="tab-advantages-client"
              :style="{
                display: currentIndex === 1 ? 'flex' : 'none',
              }"
            >
              <div
                v-for="item in advantages.slice(0, 2)"
                :key="item.title"
                class="advantages-item"
              >
                <div class="advantages-item-title">{{ item.title }}</div>
                <div class="advantages-item-subTitle">{{ item.subTitle }}</div>
              </div>
              <div
                v-for="item in advantages.slice(2)"
                :key="item.title"
                class="advantages-item item-top"
              >
                <div class="advantages-item-title">{{ item.title }}</div>
                <div class="advantages-item-subTitle">{{ item.subTitle }}</div>
              </div>
            </div>
            <div class="tab-advantages-mobile" v-show="currentIndex === 1">
              <swiper :options="swiperOptions2" style="height: 100%">
                <swiper-slide v-for="item in [0, 2]" :key="item + 'advantages'">
                  <div class="swiper-advantages-item">
                    <div class="item-title">
                      {{ advantages[item].title }}
                    </div>
                    <div class="item-subTitle">
                      {{ advantages[item].subTitle }}
                    </div>
                  </div>
                  <div class="swiper-advantages-item">
                    <div class="item-title">
                      {{ advantages[item + 1].title }}
                    </div>
                    <div class="item-subTitle">
                      {{ advantages[item + 1].subTitle }}
                    </div>
                  </div>
                </swiper-slide>
              </swiper>
              <div class="swiper-pagination-advantages"></div>
            </div>
            <div v-show="currentIndex === 2" class="honor">
              <div class="honor-client">
                <swiper :options="swiperOptions3">
                  <swiper-slide
                    v-for="(honotItem, index) in honor"
                    :key="index + 'honor-client'"
                  >
                    <div class="honor-top">
                      <div
                        v-for="item in honotItem.top"
                        :key="item.imgSrc"
                        class="honor-top-box"
                      >
                        <img :src="item.imgSrc" alt="" />
                        <div class="honor-title">{{ item.title }}</div>
                      </div>
                    </div>
                    <div class="honor-bottom">
                      <div
                        v-for="(item, index) in honotItem.bottom"
                        :key="index"
                        class="bottom-container"
                      >
                        <div
                          class="description-text"
                          v-for="(description, _index) in item"
                          :key="_index"
                        >
                          <img :src="unionLeft" alt="" />
                          <div v-if="Array.isArray(description)">
                            <div v-for="text in description" :key="text">
                              {{ text }}
                            </div>
                          </div>
                          <div v-else>
                            {{ description }}
                          </div>
                          <img :src="unionRight" alt="" />
                        </div>
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
                <div class="swiper-pagination-honor"></div>
              </div>
              <div class="honor-mobile">
                <div>
                  <swiper :options="swiperOptions4">
                  <swiper-slide
                    v-for="(honotItem, index) in honorMobile"
                    :key="index + 'honor-mobile'"
                  >
                    <div class="honor-top">
                      <div
                        v-for="item in honotItem.top"
                        :key="item.imgSrc"
                        class="honor-top-box"
                      >
                        <img :src="item.imgSrc" alt="" />
                        <div class="honor-title">{{ item.title }}</div>
                      </div>
                    </div>
                  </swiper-slide>
                </swiper>
                </div>
                <div class="swiper-pagination-honormobile"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import boxBackGroundImg from "../../assets/img/home/advantags.png";
import honor1 from "../../assets/img/home/honor/honor1.png";
import honor2 from "../../assets/img/home/honor/honor2.png";
import honor3 from "../../assets/img/home/honor/honor3.png";
import honor4 from "../../assets/img/home/honor/honor4.png";
import honor5 from "../../assets/img/home/honor/honor5.png";
import honor6 from "../../assets/img/home/honor/honor6.png";
import honor7 from "../../assets/img/home/honor/honor7.png";
import honor8 from "../../assets/img/home/honor/honor8.png";
import unionLeft from "../../assets/svg/union-left.svg";
import unionRight from "../../assets/svg/union-right.svg";

export default {
  name: "Advantages",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      currentIndex: 0,
      teamText: [
        { title: "10+", subTitle: "核心成员十年以上从业经验" },
        { title: "20+", subTitle: "ACM/ICPC World Final 等国际奖项" },
        { title: "95%+", subTitle: "研发团队毕业于国内外知名院校" },
      ],
      teamMember: [
        {
          name: "李佐凡",
          subTitle: "联合创始人 & CTO",
          description: [
            "宾夕法尼亚大学",
            "上海交通大学 ACM 班",
            "曾就职 BlackRock",
            "12 年金融行业从业经验",
          ],
        },
        {
          name: "王浚澎",
          subTitle: "联合创始人 & CEO",
          description: ["大连理工大学", "新加坡知名基金联合创始人"],
        },
        {
          name: "郑媛姿",
          subTitle: "首席运营官",
          description: [
            "考文垂大学",
            "专注企业长远战略规划",
            "负责公司整体运营",
          ],
        },
        {
          name: "朱为",
          subTitle: "北京分公司合伙人",
          description: ["华中科技大学", "10 年互联网资深架构师"],
        },
        {
          name: "乔丹",
          subTitle: "非凸AI实验室主任",
          description: [
            "北京大学",
            "大型互联网公司10年从业经验",
            "负责搜索、推荐等大规模分布式机器学习策略的开发和迭代",
          ],
        },
        {
          name: "曹迅",
          subTitle: "新加坡分公司合伙人",
          description: ["大连理工大学", "新加坡知名团队核心架构师"],
        },
        // {
        //   name: "Kane",
        //   subTitle: "深圳分公司合伙人",
        //   description: ["武汉大学", "多年工程实践及策略研究经验"],
        // },
        // {
        //   name: "Harold",
        //   subTitle: "产品总监",
        //   description: [
        //     "中国矿业大学",
        //     "7 年金融从业经验",
        //     "专注于打造优质算法产品",
        //   ],
        // },
      ],
      advantages: [
        {
          title: "极致技术",
          subTitle:
            "Rust 全栈开发，极大提升研发效能；纳秒级延时优化，带来最大化收益",
        },
        {
          title: "智能交易逻辑",
          subTitle:
            "融合多种机器学习模型，精细捕捉市场信号，自动完成海量交易，稳健获取超额收益",
        },
        {
          title: "自建算力集群",
          subTitle:
            "数亿级算力投入，自建超算中心，实现模型及策略高效迭代，保证算法持续有效",
        },
        {
          title: "专业服务",
          subTitle:
            "极速响应客户，满足定制化需求；持续迭代更新，适应新的市场变化",
        },
      ],
      honor: [
        {
          top: [
            {
              imgSrc: honor1,
              title: "上海市专精特新企业",
            },
            {
              imgSrc: honor2,
              title: "国家高新技术企业",
            },
            {
              imgSrc: honor3,
              title: "创新型中小企业",
            },
            {
              imgSrc: honor4,
              title: "软件企业证书",
            },
          ],
          bottom: [
            ["证监会信息技术系统备案", "“双软”企业"],
            [
              "上海市人工智能行业协会会员单位",
              "中国人工智能产业发展联盟成员单位",
            ],
            [
              "智能投研技术联盟（ITL）核心成员",
              ["第五届全球金融科技创业大赛", "“最具创新力企业”"],
            ],
          ],
        },
        {
          top: [
            {
              imgSrc: honor5,
              title: "毕马威中国",
            },
            {
              imgSrc: honor6,
              title: "财联社",
            },
            {
              imgSrc: honor7,
              title: "创·在上海",
            },
            {
              imgSrc: honor8,
              title: "上海市经信委",
            },
          ],
          bottom: [
            [
              ["复旦大学管理学院", "“2023-2025年度合作伙伴”"],
              ["WAIC2024 全球创新项目路演", "TOP20"],
            ],
            [
              ["2024 GOLDEN TURBO", "“年度最具成长力创新项目”奖"],
              "2024 IDC中国新兴金融科技50榜单",
            ],
            [
              "2024人工智能应用标杆TOP100",
              ["朋湖榜2024数字技术创新企业", "TOP20"],
            ],
          ],
        },
      ],
      honorMobile: [
        {
          top: [
            {
              imgSrc: honor1,
              title: "上海市专精特新企业",
            },
            {
              imgSrc: honor2,
              title: "国家高新技术企业",
            },
          ],
        },
        {
          top: [
            {
              imgSrc: honor3,
              title: "创新型中小企业",
            },
            {
              imgSrc: honor4,
              title: "软件企业证书",
            },
          ],
        },
        {
          top: [
            {
              imgSrc: honor5,
              title: "毕马威中国",
            },
            {
              imgSrc: honor6,
              title: "财联社",
            },
          ],
        },
        {
          top: [
            {
              imgSrc: honor7,
              title: "创·在上海",
            },
            {
              imgSrc: honor8,
              title: "上海市经信委",
            },
          ],
        },
      ],
      boxBackGroundImg,
      currentTeamMember: 0,
      swiperOptions: {
        speed: 500,
        autoplay: false,
        pagination: {
          el: ".swiper-pagination-team",
          type: "bullets",
          clickable: true,
        },
      },
      swiperOptions2: {
        speed: 500,
        autoplay: false,
        pagination: {
          el: ".swiper-pagination-advantages",
          type: "bullets",
          clickable: true,
        },
      },
      swiperOptions3: {
        speed: 500,
        autoplay: false,
        pagination: {
          el: ".swiper-pagination-honor",
          type: "bullets",
          clickable: true,
        },
      },
      swiperOptions4: {
        speed: 500,
        autoplay: false,
        pagination: {
          el: ".swiper-pagination-honormobile",
          type: "bullets",
          clickable: true,
        },
      },
      unionLeft,
      unionRight,
    };
  },
  methods: {
    handleClick(index) {
      this.currentIndex = index;
    },
    onTeamMemberClick(index) {
      this.currentTeamMember = index;
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #1d2129;

  .title {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    margin: 72px 0px 44px 0px;
  }

  .top {
    display: none;
  }

  .bottom {
    padding-bottom: 72px;
    @media screen and (max-width: 1360px) {
      padding-left: 20px;
      padding-right: 20px;
    }

    .tab-advantages {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 80px;
      border-bottom: 1px solid #fff;
      margin-bottom: 40px;
      .item {
        cursor: pointer;
        padding: 14px 16px;
        padding-bottom: 0px;
        &::after {
          content: "";
          margin-top: 14px;
          display: block;
          width: 100%;
          height: 2px;
          background-color: #1c55fb;
          transform: scaleX(0);
          transition: transform 0.5s;
        }
      }

      .active {
        color: #1c55fb;
        font-weight: 600;
        &::after {
          transform: scaleX(1);
        }
      }
    }

    .tab-content {
      margin: 0 auto;
      max-width: 1360px;
      .team {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        min-height: 336px;
        .left {
          display: flex;
          flex-direction: column;
          gap: 34px;
          width: 180px;
          .item {
            text-align: left;
            .item-title {
              color: #1c55fb;
              font-size: 32px;
              font-style: normal;
              font-weight: 600;
              line-height: 38px;
              margin-bottom: 14px;
            }
            .item-subTitle {
              overflow: hidden;
              font-size: 14px;
              line-height: 22px;
            }
          }
        }
        .right {
          border: 1px solid #fff;
          background: url(../../assets/img/home/advantages-background.png),
            linear-gradient(96deg, #fff 0%, #f3f6fb 30%);
          background-size: contain;
          background-blend-mode: multiply;
          background-repeat: no-repeat;
          background-position: right bottom;
          width: 100%;
          min-height: inherit;
          padding: 22px;
          max-width: 1060px;

          .tab-advantages-client {
            flex-wrap: wrap;
            justify-content: space-between;
          }
          .tab-advantages-mobile {
            display: none;
          }
          .tab-team-mobile {
            display: none;
          }

          .item {
            cursor: pointer;
            display: flex;
            padding: 14px;
            gap: 8px;
            text-align: left;
            justify-content: space-between;
            transition: width 0.5s ease;
            width: 33.333%;
            white-space: noWrap;
            .item-name {
              font-size: 20px;
              font-weight: 600;
              line-height: 26px;
              margin-bottom: 14px;
            }
            .item-subTitle {
              font-size: 14px;
              line-height: 22px;
            }
            .item-description {
              display: none;
              .description {
                max-width: 220px;
                overflow: hidden;
                white-space: normal;
                font-size: 12px;
                line-height: 20px;
              }
            }
            &:hover {
              background-color: #1c55fb;
              background-image: url(../../assets/svg/advantages-item.svg);
              background-repeat: no-repeat;
              background-position: right -23% bottom -200%;
              width: 35%;
              div {
                color: #fff !important;
              }
              .item-description {
                display: block;
              }
            }
          }
          .advantages-item {
            padding: 16px;
            text-align: left;
            width: 50%;
            max-width: 450px;
            .advantages-item-title {
              color: #1c55fb;
              font-size: 24px;
              font-weight: 600;
              line-height: 28px;
            }
            .advantages-item-subTitle {
              color: #1d2129;
              font-size: 16px;
              line-height: 24px;
              margin-top: 12px;
            }
          }
          .item-top {
            margin-top: 38px;
          }
          .honor {
            color: #1d2129;
            .honor-mobile {
              display: none;
            }
            .honor-client {
              display: flex;
              position: relative;
              :deep(.swiper-slide) {
                width: 100% !important;
              }
              .swiper-pagination-honor {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -15px;
                display: flex;
                gap: 8px;

                :deep(.swiper-pagination-bullet) {
                  width: 36px;
                  height: 6px;
                  border-radius: 999px;
                  background-color: rgba(28, 85, 251, 0.8);
                  display: inline-block;
                }
                /* 当前选中的小圆点样式（可自定义颜色等突出显示） */
                :deep(.swiper-pagination-bullet-active) {
                  background-color: #1c55fb;
                }
              }
            }
            .honor-top {
              display: flex;
              justify-content: space-between;
              gap: 12px;

              .honor-top-box {
                background-color: #fff;
                border-radius: 8px;
                overflow: hidden;
                flex: 1;
                img {
                  width: 100%;
                  object-fit: cover;
                }
              }
              .honor-title {
                padding: 8px 0px;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                background: linear-gradient(112deg, #1c55fb 0%, #001776 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
            .honor-bottom {
              display: flex;
              gap: 10px;
              justify-content: space-between;
              text-align: left;
              margin-top: 30px;
              .bottom-container {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 10px;
              }
              .description-text {
                height: 35px;
                padding: 0px 12px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 12px;
                text-align: center;
                border: 0.5px solid #e8f2ff;
                border-radius: 999px;
                background: #fff;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: map-get($--ft-width, mobile)) {
    background-size: cover;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    .title {
      margin: 2rem 0 1.5rem 0;
      font-size: 1.25rem;
      font-weight: 600;
      line-height: 1.625rem;
    }

    .top {
      display: flex;
      text-align: left;
      gap: 0.5rem;
      justify-content: space-between;
      margin-bottom: 1.5rem;
      .top-item {
        .top-item-title {
          color: #1c55fb;
          font-size: 1.25rem;
          font-weight: 600;
          line-height: 1.625rem;
        }
        .top-item-subTitle {
          color: #1d2129;
          font-size: 0.625rem;
          line-height: 1.125rem;
        }
      }
    }

    .bottom {
      padding: 0;
      padding-bottom: 3.13rem;
      .tab-advantages {
        margin-bottom: 0.75rem;
        gap: 0;
        justify-content: space-between;
        .item {
          width: 5.8rem;
          font-size: 1rem;
          line-height: 1.5rem;
          padding: 0;
          &::after {
            position: relative;
            bottom: -0.1rem;
            margin-top: 0.38rem;
          }
        }
      }
      .tab-content {
        .team {
          min-height: auto;
          .left {
            display: none;
          }
          .right {
            padding: 0;
            height: 10.25rem;
            .tab-team-client {
              display: none;
            }
            .tab-team-mobile {
              display: block;
              position: relative;
              .swiper-pagination-team {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -1.75rem;
                display: flex;
                gap: 0.5rem;

                :deep(.swiper-pagination-bullet) {
                  width: 1.5rem;
                  height: 0.375rem;
                  border-radius: 999px;
                  background-color: rgba(28, 85, 251, 0.8);
                  display: inline-block;
                }
                /* 当前选中的小圆点样式（可自定义颜色等突出显示） */
                :deep(.swiper-pagination-bullet-active) {
                  background-color: #1c55fb;
                }
              }
              .swiper-item {
                display: flex;
                height: calc(5.125rem - 1px);
                padding: 0 0.88rem;
                justify-content: space-between;
                text-align: left;

                .mobile-item-name {
                  font-size: 1rem;
                  font-weight: 600;
                  line-height: 1.5rem;
                  margin-bottom: 0.88rem;
                }
                .mobile-item-subTitle {
                  font-size: 0.875rem;
                  line-height: 1.25rem;
                }

                .mobile-item-description {
                  display: none;
                  margin-top: auto;
                  margin-bottom: auto;
                  font-size: 0.625rem;
                  max-width: 11.75rem;
                  line-height: 1.125rem;
                }
              }

              .swiper-item-active {
                background-color: #1c55fb;
                overflow: hidden;
                color: #fff;
                background-image: url(../../assets/svg/advantages-item.svg);
                background-size: 5.29rem 4.84rem;
                background-repeat: no-repeat;
                background-position: right -3% bottom -200%;
                .mobile-item-description {
                  display: block;
                }
              }
            }
            .tab-advantages-client {
              display: none !important;
            }
            .tab-advantages-mobile {
              display: block;
              text-align: left;
              height: 100%;
              position: relative;

              .swiper-advantages-item {
                padding: 0.5rem;
                height: 50%;

                .item-title {
                  color: #1c55fb;
                  font-size: 0.875rem;
                  font-weight: 600;
                  line-height: 1.375rem;
                  margin-bottom: 0.25rem;
                }
                .item-subTitle {
                  color: #1d2129;
                  font-size: 0.75rem;
                  line-height: 1.25rem;
                }
              }

              .swiper-pagination-advantages {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: -1.75rem;
                display: flex;
                gap: 0.5rem;

                :deep(.swiper-pagination-bullet) {
                  width: 1.5rem;
                  height: 0.375rem;
                  border-radius: 999px;
                  background-color: rgba(28, 85, 251, 0.8);
                  display: inline-block;
                }
                /* 当前选中的小圆点样式（可自定义颜色等突出显示） */
                :deep(.swiper-pagination-bullet-active) {
                  background-color: #1c55fb;
                }
              }
            }

            .honor {
              padding: 0.5rem;
              height: 100%;
              display: flex;
              align-items: center;

              .honor-client {
                display: none;
              }

              .honor-mobile {
                position: relative;
                display: block;
                max-width: 100%; // 限制最大宽度

                .honor-top {
                  display: flex;
                  height:100%;
                  justify-content: center;
                  align-items: center;
                  gap: 0.5rem;

                  .honor-top-box {
                    flex: none;
                    max-width: 9.6rem;
                    img {
                      object-fit: contain;
                    }

                    .honor-title {
                      font-size: 0.75rem;
                      line-height: 1rem;
                    }
                  }
                }
                .swiper-pagination-honormobile {
                  position: absolute;
                  left: 50%;
                  transform: translateX(-50%);
                  bottom: -3.05rem;
                  display: flex;
                  gap: 0.5rem;

                  :deep(.swiper-pagination-bullet) {
                    width: 1.5rem;
                    height: 0.375rem;
                    border-radius: 999px;
                    background-color: rgba(28, 85, 251, 0.8);
                    display: inline-block;
                  }
                  /* 当前选中的小圆点样式（可自定义颜色等突出显示） */
                  :deep(.swiper-pagination-bullet-active) {
                    background-color: #1c55fb;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
