<template>
  <div class="box" id="banner-top">
    <div class="vedio-box">
      <video
        ref="videoRef"
        autoplay
        loop
        muted
        playsinline
        data-wf-ignore="true"
        data-object-fit="cover"
        webkit-playsinline
        :controls="false"
      >
        <source src="../../assets/video/banner.mp4" type="video/mp4" />
      </video>
    </div>
    <div class="banner-content">
      <div class="center">
        <div class="center-top">
          <div class="title">追求卓越算法 科技赋能投资</div>
        </div>

        <div class="info-box">
          <div v-for="item in list" :key="item.num" class="info-item">
            <div class="bar"></div>
            <div style="display: flex; gap: 10px">
              <div class="num">{{ item.num }}</div>
              <div class="type">{{ item.type }}</div>
            </div>
            <div class="text">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Banner",
  data() {
    return {
      list: [
        { num: "300+", type: "", text: "团队规模" },
        { num: "200亿+", type: "", text: "日均成交额" },
        {
          num: "5万+",
          type: "",
          text: "APP用户",
        },
        { num: "30+", type: "", text: "专利/软件著作权" },
      ],
    };
  },
};
</script>
<style lang="scss" scoped>
.box {
  position: relative;
  color: #fff;
  max-height: 800px;
  background: linear-gradient(180deg, #040306 0%, #050506 100%);
  .banner-content {
    display: flex;
    align-items: center;
    position: absolute;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .center {
      margin: 0 auto;
      width: 100%;
      height: 100%;
      position: relative;
      text-align: center;

      @media screen and (max-width: 1360px) {
        .center-top,
        .info-box {
          padding: 0px 20px;
        }
      }

      .center-top {
        position: absolute;
        width: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .title {
          color: #fff;
          font-size: 44px;
          font-weight: 600;
          line-height: 68px;
        }
      }

      .info-box {
        width: 100%;
        max-width: 1360px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 100px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .info-item {
          text-align: left;
          height: 105px;
          display: flex;
          flex-direction: column;
          gap: 20px;
          .bar {
            width: 30px;
            height: 4px;
            border-radius: 0px 0px 2px 2px;
            background: #1c55fb;
          }

          .num,
          .type {
            font-size: 32px;
            font-weight: 500;
            line-height: 32px;
            color: #fff;
          }

          .text {
            color: #fff;
            font-size: 16px;
            font-weight: 300;
            line-height: 24px;
          }
        }
      }
    }
  }
  .vedio-box {
    display: flex;
    video {
      max-height: 800px;
      width: 100%;
      object-fit: contain;
    }
  }

  @media screen and (max-width: map-get($--ft-width, mobile)) {
    padding-top: 3.75rem;

    .banner-content {
      .center {
        .center-top {
          .title {
            font-size: 1.125rem;
            font-weight: 600;
            line-height: 1.5rem;
          }
        }
        .info-box {
          bottom: 0.62rem;
          padding: 0 0.75rem;

          .info-item {
            height: auto;
            gap: 0;
            .bar {
              width: 1.5rem;
              height: 0.125rem;
            }
            .num {
              font-size: 0.875rem;
              font-weight: 600;
              line-height: 1.375rem;
            }
            .text {
              font-size: 0.625rem;
              font-weight: 300;
              line-height: 1.125rem;
            }
          }
        }
      }
    }

    .vedio-box {
      video {
        width: 100%;
        height: 13.75rem;
      }
    }
  }
}
</style>
