<template>
  <div class="home">
    <SideBar />
    <!-- 顶部banner -->
    <Banner/>
    <!-- 吸顶锚点 -->
    <div style="position: relative; height: 0px">
      <TopNav />
    </div>
    <!-- 算法生态 -->
    <Ecology />
    <!-- 产品服务-->
    <Server />
    <!-- 合作案例 -->
    <Example />
    <!-- 非凸优势 -->
    <Advantages />
    <!-- 新闻动态 -->
    <FtNews />
    <!-- 解决方案 -->
    <Solution/>
  </div>
</template>

<script>
import "swiper/css/swiper.css";
import SideBar from "../components/home/SideBar.vue";
import TopNav from "../components/home/TopNav.vue";
import Ecology from "../components/home/Ecology.vue";
import Server from "../components/home/Server.vue";
import Example from "../components/home/Example.vue";
import Advantages from "../components/home/Advantages.vue";
import FtNews from "../components/home/FtNews.vue";
import Solution from '../components/home/Solution.vue'
import Banner from '../components/home/Banner.vue'

export default {
  name: "Home",
  data() {
    return {
      news: [],
      notTouched: false,
      collapse: 0,
      swiperOptionProduct: {
        pagination: {
          el: ".product-swiper-pagination",
        },
        spaceBetween: 30,
      },
      swiperOptionHome: {
        loop: true,
        allowTouchMove: false,
        autoplay: {
          delay: 2000,
          disableOnInteraction: true,
        },
      },
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        loop: true,
        allowTouchMove: false,
        autoplay: {
          delay: 3000,
          disableOnInteraction: true,
        },
      },
    };
  },
  components: {
    // Swiper,
    // SwiperSlide,
    // NewsCard,
    SideBar,
    TopNav,
    Ecology,
    Server,
    Example,
    Advantages,
    FtNews,
    Solution,
    Banner,
  },
  computed: {
    productSwiper() {
      return this.$refs.productSwiper.$swiper;
    },
    isWeixin() {
      var ua = navigator.userAgent.toLowerCase();
      return (
        ua.match(/MicroMessenger/i) == "micromessenger" &&
        ua.match(/wxwork/i) == null
      );
    },
    isAndroid() {
      return navigator.userAgent.match(/android/i);
    },
  },
  methods: {
    onHover(event) {
      document.querySelectorAll(".location").forEach((ele) => {
        ele.classList.remove("active");
      });
      event.target.classList.add("active");
    },
    onProductSlideChange() {
      this.collapse = this.productSwiper.activeIndex;
    },
  },
  mounted() {
    this.news = [
      {
        id: 1,
        title: "非凸联合创始人李佐凡：将“科技赋能投资”作为自己的终身事业",
        text: "我们希望与行业同频呼吸，愿意尝试，敢于试错，让中国自己的数智交易实现跨越式发展。",
        tags: ["人物", "联合创始人"],
        img_src: "assets/img/news/10/01-李佐凡.jpg",
        author: "非凸人物",
        date: "2022年6月24日",
        link: "news10",
        author_img_src: "assets/img/logo/logo_50.jpeg",
      },
      {
        id: 2,
        title: "第49届ICPC亚洲区域赛·南京站，非凸科技持续支持赛事举办",
        text: "人才是持续推动科学技术进步的关键，非凸科技将持续支持赛事的举办，为国际竞赛界输送企业知识和众多资源。",
        tags: ["新闻", "ICPC"],
        img_src: "assets/img/news/93封面.jpg",
        author: "非凸新闻",
        date: "2024年11月11日",
        link: "https://mp.weixin.qq.com/s/zmz5QclQ3areF0Gk98LqQg",
        author_img_src: "assets/img/logo/logo_50.jpeg",
      },
      {
        id: 3,
        title: "非凸科技助力第49届ICPC亚洲区域赛（成都）成功举办",
        text: "期待与产学研各界专家、青年才俊一起，推动基础科学理论研究的重大突破，促进技术应用创新，共同为全球繁荣发展贡献力量。",
        tags: ["新闻", "ICPC"],
        img_src: "assets/img/news/92封面.jpg",
        author: "非凸新闻",
        date: "2024年11月4日",
        link: "https://mp.weixin.qq.com/s/6bIWT-VroFFPN5otHPvPDQ",
        author_img_src: "assets/img/logo/logo_50.jpeg",
      },
      {
        id: 4,
        title: "非凸科技银牌赞助GOSIM CHINA 2024，并受邀出席Rust分论坛",
        text: "与160+国内外行业领袖、技术专家，1000+一线开发者、开源人才，共同探讨开源生态系统的最新进展与未来趋势。",
        tags: ["新闻", "开源技术盛会"],
        img_src: "assets/img/news/91封面.jpg",
        author: "非凸新闻",
        date: "2024年10月23日",
        link: "https://mp.weixin.qq.com/s/oEcmPOUBfX7uqDo_Tdt0MA",
        author_img_src: "assets/img/logo/logo_50.jpeg",
      },
      {
        id: 5,
        title: "非凸算法全面上线国融证券",
        text: "非凸智能底仓增强算法、拆单算法顺利上线国融证券，全面满足投资者在多种终端上的智能交易服务需求。",
        tags: ["新闻", "国融证券"],
        img_src: "assets/img/news/90/封面.jpg",
        author: "非凸新闻",
        date: "2024年10月11日",
        link: "news90",
        author_img_src: "assets/img/logo/logo_50.jpeg",
      },
      {
        id: 6,
        title: "非凸智能算法全面上线中金公司",
        text: "全新升级，平稳运行，全方位满足投资者降低交易成本、优化交易执行、提升交易效率等切实需求。",
        tags: ["新闻", "中金公司"],
        img_src: "assets/img/news/89/封面.jpg",
        author: "非凸新闻",
        date: "2024年9月20日",
        link: "news89",
        author_img_src: "assets/img/logo/logo_50.jpeg",
      },
    ];

    // var scope = this;
    // document.addEventListener(
    //   "WeixinJSBridgeReady",
    //   function () {
    //     let video = document.getElementById("video");
    //     if (video != null && video != undefined) {
    //       document.getElementById("video").play();
    //     }
    //   },
    //   true
    // );
    // document.addEventListener(
    //   "touchstart",
    //   function () {
    //     let video = document.getElementById("video");
    //     if (video != null && video != undefined) {
    //       scope.notTouched = false;
    //       document.getElementById("video").play();
    //     }
    //   },
    //   true
    // );

    // document
    //   .getElementById("collapse-algorithm")
    //   .addEventListener("show.bs.collapse", function () {
    //     scope.collapse = 0;
    //   });

    // document
    //   .getElementById("collapse-底仓增强")
    //   .addEventListener("show.bs.collapse", function () {
    //     scope.collapse = 1;
    //   });

    // document
    //   .getElementById("collapse-platform")
    //   .addEventListener("show.bs.collapse", function () {
    //     scope.collapse = 2;
    //   });
  },
};
</script>

<style lang="scss" scoped>
.anything {
  background-size: cover;
  background-position: center;
  background-image: url("~@/assets/img/04.jpg");
}
section {
  overflow: hidden;
}
section.blue {
  color: $--ft-white;
  background-color: $--ft-primary-1;
}
.feature {
  display: inline-flex;
  align-items: center;

  justify-content: center;
  height: 3rem;
  width: 3rem;
  font-size: 1.5rem;
}

.logo_50 {
  width: 50px;
}
#infinity {
  position: absolute;
  width: 720px;
  height: 600px;
  top: -4rem;
  left: calc(50% - 360px);
  z-index: 0;
}

#infinity:before,
#infinity:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 360px;
  height: 360px;
  border: 120px solid #f1f1f1;
  -moz-border-radius: 300px 300px 0 300px;
  border-radius: 300px 300px 0 300px;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#infinity:after {
  left: auto;
  right: 0;
  border-radius: 300px 300px 300px 0;
  -moz-border-radius: 300px 300px 300px 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.location {
  height: 200px;
  transform: skewX(-10deg);
  overflow: hidden;
}
.location.active {
  width: calc(200% / 5);
}
.location,
.location .background,
.location .text {
  transition: all ease-out 0.3s;
}

.location > div {
  transform: skewX(10deg);
  transform-origin: bottom;
}

.location > .background {
  background-size: cover;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-width: 30rem;
}
.location.active .background {
  width: 45rem;
  opacity: 0.45;
}
.beijing {
  background-image: url("../assets/img/teams/beijing.jpg");
}
.shanghai {
  background-image: url("../assets/img/teams/shanghai.jpg");
}
.chengdu {
  background-image: url("../assets/img/teams/chengdu.jpg");
}
.location .text {
  text-align: center;
  color: white;
  position: relative;
  top: 2.5rem;
  width: fit-content;
  margin: auto;
  font-weight: 800;
}
.location.active .text {
  color: black;
  cursor: default;
}
.location .title {
  font-size: 3.5rem;
  display: inline-block;
  filter: drop-shadow(6px 6px 2px black);
}
.location .title a {
  color: unset;
}
.location.active .title {
  filter: none;
}
.video-section {
  background-color: black;
  margin-top: -8rem;
  min-height: unset;
}
.video-section video {
  width: 100%;
  object-fit: fill;
}
.accordion-button:not(.collapsed) {
  box-shadow: unset;
  background-color: unset;
}
.accordion-button.collapsed {
  opacity: 0.65;
}
.accordion-button:after {
  display: none;
}
.collapse-image {
  width: 100%;
  height: 100%;
}
.accordion * {
  background: unset;
}
.swiperContainer {
  min-height: 708px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  justify-content: center;

  &.banner {
    background-image: url("../assets/img/home/banner.png");
  }
  .swiper-text {
    z-index: 1;
    padding-left: 240px;
  }
}
.plan img {
  width: 8rem;
}
.plan {
  text-align: center;
}
.cover-text > div {
  font-size: 1.8rem;
  font-weight: 400;
  filter: drop-shadow(2px 4px 6px black);
}
.card {
  border: none;
  border-radius: 0;
}
.card p {
  font-size: 1rem;
  font-weight: normal;
  color: $--ft-gray;
  margin-bottom: 0;
}
.card > div {
  width: 15rem;
}
@media screen and (max-width: map-get($container-max-widths, lg)) {
  .location {
    position: relative;
    transform: none;
  }
  .location.active {
    width: 100%;
  }
  .location > div {
    transform: none;
  }
  .video-section {
    margin-top: 0;
    padding-top: 5rem;
  }
  .swiperContainer {
    min-height: 400px !important;
    justify-content: end;
    .swiper-text {
      padding-left: 10px;
      width: calc(9rem + 15vw);
      font-size: 1rem !important;
    }
  }
}
</style>
