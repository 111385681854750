<template>
  <div class="box">
    <div class="title">一站式数智交易领域服务解决方案专家</div>
    <div class="btn-box">
      <!-- <div>
        <FtButton>预约路演</FtButton>
      </div> -->
      <div class="right-primary-btn">
        <FtButton @onClick="onBusinessClick" style="border: none;">商务合作</FtButton>
      </div>
    </div>
  </div>
</template>
<script>
import FtButton from "../FtButton.vue";

export default {
  name: "Solution",
  components: {
    FtButton,
  },
  methods: {
    onBusinessClick() {
      this.$router.push({
        path: "/business",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  flex-direction: column;
  padding: 64px 0px;
  background-color: #e8f2ff;
  color: #1d2129;
  // background: url(../../assets/img/home/solution.png) center no-repeat;
  // background-size: cover;

  .title {
    margin: 0 auto;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    margin-bottom: 44px;
  }

  .btn-box {
    display: flex;
    gap: 24px;
    margin: 0 auto;

    button {
      border-radius: 999px;
      font-size: 16px;
      line-height: 24px;
      padding: 7px 20px;
      width: 160px;
      color: #1c55fb;
    }

    .right-primary-btn {
      --hoverBgColor: #0f1490;
      button {
        color: #fff;
        background-color: #1c55fb;
      }
    }
  }

  @media screen and (max-width: map-get($--ft-width, mobile)) {
    padding: 2rem;
    .title{
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 1rem;
    }

    button{
      width: auto !important;
      padding: 0.4375rem 1rem !important;
      font-size: 0.875rem !important;
      line-height: 1.375rem !important;
    }
  }

}
</style>
