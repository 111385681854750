var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box",attrs:{"id":"ftNews"}},[_c('div',{staticClass:"title"},[_vm._v("了解非凸最新动态")]),_c('div',{staticClass:"subTitle"},[_c('router-link',{attrs:{"to":"/about/news"}},[_vm._v(" 更多动态新闻 ")])],1),_c('div',{staticClass:"bottom"},[_c('div'),_c('div',{staticClass:"left-news"},[_c('div',{staticClass:"left-top"},[_c('div',{staticClass:"left-title largeTitle"},[_vm._v("非凸新闻")]),_c('div',{staticClass:"right-control"},[_c('div',{class:{ prev: true, disabled: _vm.currentIndex === 0 },on:{"click":_vm.handlePrev}},[_c('div')]),_c('div',{staticStyle:{"width":"27px","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.currentIndex + 1 + " / " + _vm.newsList.length)+" ")]),_c('div',{class:{
              next: true,
              disabled: _vm.currentIndex === _vm.newsList.length - 1,
            },on:{"click":_vm.handleNext}},[_c('div')])])]),_c('div',{staticClass:"left-bottom"},[_c('div',{staticClass:"news"},[_c('div',{staticClass:"imgBox"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.newsList[_vm.currentIndex].imgSrc),expression:"newsList[currentIndex].imgSrc"}],attrs:{"alt":""}})])]),_c('div',{staticClass:"news-content"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"text-overflow middleTitle"},[_vm._v(" "+_vm._s(_vm.newsList[_vm.currentIndex].title)+" ")]),_c('div',{staticClass:"text-overflow"},[_vm._v(" "+_vm._s(_vm.newsList[_vm.currentIndex].content)+" ")]),_c('FtButton',{staticStyle:{"margin-top":"56px","height":"32px","padding":"7px 20px","font-size":"14px","border-radius":"999px","border-color":"#fff","min-width":"102px"},on:{"onClick":function($event){return _vm.handelDetailBtnClick(_vm.newsList[_vm.currentIndex])}}},[_vm._v(" 查看详情 ")])],1)])])]),_c('div',{staticClass:"center"}),_c('div',{staticClass:"right-dynamic"},[_c('div',{staticClass:"dynamic-title largeTitle"},[_vm._v("招聘动态")]),_c('div',{directives:[{name:"lazy",rawName:"v-lazy:background-image",value:(_vm.dynamicBackgroundImg),expression:"dynamicBackgroundImg",arg:"background-image"}],staticClass:"dynamic-background"},[_c('div',{class:{
            'dynamic-info-box': true,
            transformBoxLeft: _vm.currentPosition === 1,
            transformBoxRight: _vm.currentPosition === 0,
          }},[_c('div',{staticClass:"info info-left"},[_c('div',{staticClass:"info-title middleTitle"},[_vm._v("社会招聘")]),_c('div',{staticClass:"position"},[_c('router-link',{attrs:{"to":"/job/10"}},[_vm._v("大模型应用开发工程师")]),_c('router-link',{attrs:{"to":"/job/01"}},[_vm._v(" 算法服务平台开发工程师 ")])],1),_c('FtButton',{staticStyle:{"border-color":"#fff","color":"#fff","padding":"7px 20px","height":"32px","border-radius":"999px","font-size":"14px"},on:{"onClick":_vm.handelSocialBtnClick}},[_vm._v(" 全部岗位 ")])],1),_c('div',{staticClass:"position-tab",on:{"click":_vm.onPositionTypeChange}},[_c('div',[_vm._v(_vm._s(_vm.currentPosition === 0 ? "校园招聘" : "社会招聘"))]),_c('div',{class:{
                operater: true,
                'operater-reverse': _vm.currentPosition === 0,
              }},[_c('div')])]),_c('div',{staticClass:"info info-right"},[_c('div',{staticClass:"info-title middleTitle"},[_vm._v("校园招聘")]),_c('div',{staticClass:"position"},[_c('router-link',{attrs:{"to":"/job/02"}},[_vm._v(" 软件开发工程师 ")]),_c('router-link',{attrs:{"to":"/job/03"}},[_vm._v("算法工程师")])],1),_c('FtButton',{staticStyle:{"border-color":"#fff","color":"#fff","padding":"7px 20px","height":"32px","border-radius":"999px","font-size":"14px"},on:{"onClick":_vm.handelCampusBtnClick}},[_vm._v(" 全部岗位 ")])],1)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }