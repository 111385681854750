<template>
  <div class="side-bar">
    <div class="bar-first">
      <img src="../../assets/svg/customer-service.svg" />
      <div>联系咨询</div>
      <div class="side-tooltip-first">
        <div class="item">
          <div class="img">
            <img src="../../assets/svg/phone.svg" loading="lazy" />
          </div>
          <div>
            <div class="phone">商务咨询</div>
            <div class="phone-number">182 0217 0437</div>
          </div>
        </div>
        <div class="item">
          <div class="img">
            <img src="../../assets/svg/shake.svg" loading="lazy" />
          </div>
          <div>
            <div class="phone">申请试用</div>
            <div class="phone-number">153 8016 7930</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bar-common bar-second">
      <img src="../../assets/svg/message.svg" />
      <div class="side-tooltip-second">
        <div class="title">满意度调研</div>
        <div class="score-box">
          <div class="score">
            <div>1</div>
            <div>非常不满</div>
          </div>
          <div class="score">
            <div>2</div>
            <div>不满</div>
          </div>
          <div class="score">
            <div>3</div>
            <div>一般</div>
          </div>
          <div class="score">
            <div>4</div>
            <div>满意</div>
          </div>
          <div class="score">
            <div>5</div>
            <div>非常满意</div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="bar-common bar-third">
      <img src="../../assets/svg/qrcode.svg" loading="lazy" />
      <div class="side-tooltip-second">
        <img src="../../assets/svg/image.svg" loading="lazy" />
        <div>公众号</div>
      </div>
    </div>
    <div class="bar-common" @click="backToTop" style="cursor: pointer">
      <img src="../../assets/svg/up.svg" loading="lazy" />
    </div>
  </div>
</template>

<script>
export default {
  name: "SideBar",

  methods: {
    backToTop: function () {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>

<style lang="scss" scoped>
.side-bar {
  z-index: 999;
  width: 40px;
  position: fixed;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  @media screen and (max-width: map-get($--ft-width, mobile)) {
    display: none;
  }

  .bar-first {
    display: flex;
    width: 40px;
    padding: 12px 10px;
    flex-direction: column;
    align-items: stretch;
    gap: 4px;
    background-color: #1c55fb;
    color: #fff;
    border-radius: 999px;
    font-size: 14px;
    text-align: center;
    .side-tooltip-first {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      right: calc(100% + 10px);
      display: flex;
      width: 180px;
      padding: 10px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid #e5e6eb;
      background: #fff;
      transition: all 0.3s;
      box-shadow: 0px 0px 4px 0px rgba(150, 186, 253, 0.2);

      .item {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        text-align: left;
        .phone {
          font-size: 10px;
          color: #1d2129;
          line-height: 18px;
        }
        .phone-number {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #1d2129;
        }
        .img {
          padding: 6px;
          border-radius: 999px;
          background: #f2f3f5;
        }
      }
    }

    &:hover {
      .side-tooltip-first {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .bar-common {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 999px;
    border: 1px solid #fff;
    background: linear-gradient(180deg, #dae3ff 0%, #fff 100%);
  }
  .bar-second {
    .side-tooltip-second {
      position: absolute;
      opacity: 0;
      visibility: hidden;
      right: calc(100% + 10px);
      display: flex;
      width: 240px;
      padding: 10px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid #e5e6eb;
      background: #fff;
      transition: all 0.3s;
      box-shadow: 0px 0px 4px 0px rgba(150, 186, 253, 0.2);

      .title {
        color: #1d2129;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      .score-box {
        display: flex;
        align-items: center;
        gap: 4px;

        .score {
          text-align: center;
          div:first-child {
            padding: 0px 14px;
            border-radius: 2px;
            border: 1px solid #e5e6eb;
          }

          div:last-child {
            color: #86909c;
            font-size: 8px;
            font-style: normal;
            font-weight: 400;
            line-height: 12px;
            margin-top: 4px;
          }
        }
      }
    }

    &:hover {
      .side-tooltip-second {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .bar-third {
    background: linear-gradient(180deg, #1c55fb 0%, #96bafd 100%);
    .side-tooltip-second {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      right: calc(100% + 10px);
      display: inline-flex;
      padding: 10px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      border-radius: 8px;
      border: 1px solid #e5e6eb;
      background: #fff;
      transition: all 0.3s;
      box-shadow: 0px 0px 4px 0px rgba(150, 186, 253, 0.2);

      div:last-child {
        font-size: 12px;
        width: 100%;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
      }
    }

    &:hover {
      .side-tooltip-second {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

@media screen and (max-width: map-get($container-max-widths,lg)) {
  .side-bar {
    right: 0px;
  }
}
</style>
