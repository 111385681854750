<template>
  <div class="box" id="ftNews">
    <div class="title">了解非凸最新动态</div>
    <div class="subTitle">
      <router-link to="/about/news"> 更多动态新闻 </router-link>
    </div>
    <div class="bottom">
      <div></div>
      <div class="left-news">
        <div class="left-top">
          <div class="left-title largeTitle">非凸新闻</div>
          <div class="right-control">
            <div
              :class="{ prev: true, disabled: currentIndex === 0 }"
              @click="handlePrev"
            >
              <div></div>
            </div>
            <div style="width: 27px; font-size: 12px">
              {{ currentIndex + 1 + " / " + newsList.length }}
            </div>
            <div
              :class="{
                next: true,
                disabled: currentIndex === newsList.length - 1,
              }"
              @click="handleNext"
            >
              <div></div>
            </div>
          </div>
        </div>
        <div class="left-bottom">
          <div class="news">
            <div class="imgBox">
              <img v-lazy="newsList[currentIndex].imgSrc" alt="" />
            </div>
          </div>
          <div class="news-content">
            <!-- <div class="right-control">
              <div
                :class="{ prev: true, disabled: currentIndex === 0 }"
                @click="handlePrev"
              >
                <div></div>
              </div>
              <div style="width: 27px; font-size: 12px">
                {{ currentIndex + 1 + " / " + newsList.length }}
              </div>
              <div
                :class="{
                  next: true,
                  disabled: currentIndex === newsList.length - 1,
                }"
                @click="handleNext"
              >
                <div></div>
              </div>
            </div> -->
            <div class="content">
              <div class="text-overflow middleTitle">
                {{ newsList[currentIndex].title }}
              </div>
              <div class="text-overflow">
                {{ newsList[currentIndex].content }}
              </div>
              <FtButton
                @onClick="handelDetailBtnClick(newsList[currentIndex])"
                style="
                  margin-top: 56px;
                  height: 32px;
                  padding: 7px 20px;
                  font-size: 14px;
                  border-radius: 999px;
                  border-color: #fff;
                  min-width: 102px;
                "
              >
                查看详情
              </FtButton>
            </div>
          </div>
        </div>
      </div>
      <div class="center"></div>
      <div class="right-dynamic">
        <div class="dynamic-title largeTitle">招聘动态</div>
        <div
          class="dynamic-background"
          v-lazy:background-image="dynamicBackgroundImg"
        >
          <div
            :class="{
              'dynamic-info-box': true,
              transformBoxLeft: currentPosition === 1,
              transformBoxRight: currentPosition === 0,
            }"
          >
            <div class="info info-left">
              <div class="info-title middleTitle">社会招聘</div>
              <div class="position">
                <router-link to="/job/10">大模型应用开发工程师</router-link>
                <router-link to="/job/01"> 算法服务平台开发工程师 </router-link>
              </div>
              <FtButton
                @onClick="handelSocialBtnClick"
                style="
                  border-color: #fff;
                  color: #fff;
                  padding: 7px 20px;
                  height: 32px;
                  border-radius: 999px;
                  font-size: 14px;
                "
              >
                全部岗位
              </FtButton>
            </div>
            <div class="position-tab" @click="onPositionTypeChange">
              <div>{{ currentPosition === 0 ? "校园招聘" : "社会招聘" }}</div>
              <div
                :class="{
                  operater: true,
                  'operater-reverse': currentPosition === 0,
                }"
              >
                <div></div>
              </div>
            </div>
            <div class="info info-right">
              <div class="info-title middleTitle">校园招聘</div>
              <div class="position">
                <router-link to="/job/02"> 软件开发工程师 </router-link>

                <router-link to="/job/03">算法工程师</router-link>
              </div>
              <FtButton
                @onClick="handelCampusBtnClick"
                style="
                  border-color: #fff;
                  color: #fff;
                  padding: 7px 20px;
                  height: 32px;
                  border-radius: 999px;
                  font-size: 14px;
                "
              >
                全部岗位
              </FtButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FtButton from "../FtButton.vue";
import newsImg1 from "../../assets/img/news/10/01-李佐凡.jpg";
import newsImg2 from "../../assets/img/news/99封面.jpg";
import newsImg3 from "../../assets/img/news/创在上海.jpg";
import newsImg4 from "../../assets/img/news/GDC.jpg";
import dynamicBackgroundImg from "../../assets/img/home/dynamicBackground.png";

export default {
  name: "FtNews",
  components: {
    FtButton,
  },
  data() {
    return {
      currentIndex: 0,
      newsList: [
        {
          title: "非凸联合创始人李佐凡：将“科技赋能投资”作为自己的终身事业",
          content:
            "我们希望与行业同频呼吸，愿意尝试，敢于试错，让中国自己的数智交易实现跨越式发展。",
          imgSrc: newsImg1,
          isWxLink: false,
          link: "/news10",
        },
        {
          title: "非凸科技荣获“创·在上海”国际创新创业大赛总决赛优胜企业20强",
          content:
            "经过初赛、复赛、预决赛、总决赛多轮角逐，非凸科技从万余家科技企业中强势突围，跻身总决赛优胜企业20强榜单。",
          imgSrc: newsImg3,
          isWxLink: true,
          link: "https://mp.weixin.qq.com/s?__biz=MzA3Mzg5Njc2OA==&mid=2247488620&idx=1&sn=ac2e1413ac4f37e3e995bcd4025ac21a&chksm=9e4103c868a2c4012950e69feaef725066f2da795291826fe1f9fac198e013b77f1273bf9881&mpshare=1&scene=1&srcid=03149T9eXXU9bMSFjZphuFXM&sharer_shareinfo=8b22abb19ab65389bca481d6dac9828d&sharer_shareinfo_first=8b22abb19ab65389bca481d6dac9828d&version=4.1.20.99416&platform=mac#rd",
        },
        {
          title: "非凸科技受邀参加GDC Talk，与全球开发者共探前沿技术",
          content:
            "受邀参与GDC Talk 开放麦活动，与人工智能产业同行分享技术实践，共话数智交易未来。",
          imgSrc: newsImg4,
          isWxLink: true,
          link: "https://mp.weixin.qq.com/s?__biz=MzA3Mzg5Njc2OA==&mid=2247488609&idx=1&sn=579638ce666689debcac0c05a7ae787e&chksm=9e236b3d33bfaea008f9563244e03afce244b38487b60f101a76e77c17faf1372672e859f584&mpshare=1&scene=1&srcid=0314P88zbBAIHAfo1M9QM2EG&sharer_shareinfo=962784502003be0652aa3a9ce59e6ff2&sharer_shareinfo_first=962784502003be0652aa3a9ce59e6ff2&version=4.1.20.99416&platform=mac#rd",
        },
        {
          title: "非凸科技再次斩获多项行业殊荣，感谢认可！",
          content:
            "非凸科技自主研发实力、业务表现、技术实践、成长潜力等多维度得到了行业及协会的高度认可。",
          imgSrc: newsImg2,
          isWxLink: true,
          link: "https://mp.weixin.qq.com/s/NUXiqSTd58_CoUQCCYQABA",
        },
      ],
      currentPosition: 0,
      dynamicBackgroundImg,
    };
  },
  methods: {
    handlePrev() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    },
    handleNext() {
      if (this.currentIndex < this.newsList.length - 1) {
        this.currentIndex++;
      }
    },

    onPositionTypeChange() {
      this.currentPosition = this.currentPosition === 0 ? 1 : 0;
    },

    handelDetailBtnClick(item) {
      const { isWxLink, link } = item;
      if (isWxLink) {
        return window.open(link);
      }
      return this.$router.push({
        path: link,
      });
    },

    handelSocialBtnClick() {
      this.$router.push({
        path: "/about/social",
      });
    },
    handelCampusBtnClick() {
      this.$router.push({
        path: "/about/campus",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.box {
  text-align: center;
  color: #1d2129;
  padding: 72px 0px;

  .largeTitle {
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
  }

  .middleTitle {
    font-weight: 600;
    font-size: 20px !important;
    line-height: 26px !important;
  }

  .title {
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;
  }

  .subTitle {
    a {
      text-decoration: none;
    }
    display: block;
    margin-top: 8px;
    margin-bottom: 44px;
    cursor: pointer;
    color: #1c55fb;
    font-size: 16px;
    line-height: 24px;
    text-decoration: none;
  }

  .bottom {
    display: flex;
    flex-wrap: nowrap;
    height: 380px;
    color: #fff;
    @media screen and (max-width: 915px) {
      .center {
        display: none;
      }
    }
    .left-news {
      flex: 1;
      display: flex;
      gap: 24px;
      padding-left: 20px;

      flex-direction: column;
      align-items: flex-end;
      background-color: #242424;
      padding-top: 48px;
      padding-right: 26px;

      .left-top {
        display: flex;
        justify-content: space-between;
        max-width: 633px;
        width: 100%;
      }

      .left-bottom {
        display: flex;
        gap: 20px;
      }

      .right-control {
        display: flex;
        height: 20px;
        gap: 10px;
        justify-content: flex-end;
        align-items: center;
        .prev,
        .next {
          width: 20px;
          height: 20px;
          border-radius: 999px;
          border: 0.5px solid #fff;
          cursor: pointer;
          div {
            width: 7px;
            height: 7px;
            margin: 0 auto;
            transform: translateY(6.5px) rotate(45deg) translateX(-1.5px);
            border: 1px solid #fff;
            border-left-width: 0px;
            border-bottom-width: 0px;
          }
        }
        .prev {
          div {
            transform: translateY(6px) rotate(-135deg);
          }
        }
        .disabled {
          border-color: #ffffff80;
          div {
            border-color: #ffffff80;
          }
        }
      }

      .news {
        .left-title {
          width: 96px;
          margin-bottom: 20px;
          line-height: 26px;
        }
        .imgBox {
          height: 200px;
          width: 320px;
          border-radius: 12px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .news-content {
        .content {
          text-align: left;
          .text-overflow {
            height: 44px;
            max-width: 293px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 14px;
            line-height: 22px;
          }
          div:first-child {
            height: 52px;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
  .center {
    height: 100%;
    width: 48px;
    background: linear-gradient(
      -83deg,
      #1c55fb,
      #1c55fb 49.9%,
      #242424 50.1%,
      #242424
    );
  }

  .right-dynamic {
    flex: 1;
    text-align: left;
    padding-right: 20px;
    background-color: #1c55fb;
    padding-left: 20px;
    padding-top: 48px;
    .dynamic-title {
      margin-bottom: 23px;
    }
    .dynamic-background {
      overflow: hidden;
      max-width: 634px;
      width: 100%;
      background-position: center;
      background-repeat: no-repeat;
      border: 0.5px solid rgba(255, 255, 255, 0.5);
    }
    .dynamic-info-box {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      transition: all 0.5s ease;

      .info {
        min-width: calc(100% - 104px);
        color: #fff;
        padding: 32px 32px;
        transition: all 0.5s ease;
        --hoverBgColor: #fff;
        --hoverColor: #1c55fb;
        --hoverTextColor: #1c55fb;
        --borderHoverColor: #fff;
        .info-title {
          margin-bottom: 16px;
        }

        .position {
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
          margin-bottom: 24px;
          transition: all 0.5s ease;
          a {
            display: block;
            text-decoration: none;
            color: inherit;
            flex: 1 0 180px;
            border: 0.5px solid rgba(255, 255, 255, 0.5);
            text-align: center;
            padding: 8px 0px;
            font-size: 14px;
            font-weight: 600;
            line-height: 22px;
            border-radius: 4px;
            background: rgba(255, 255, 255, 0.05);
          }
        }
      }

      .position-tab {
        cursor: pointer;
        min-width: 104px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 32px;
        background: #1c55fb;
        border: 1px solid rgba(255, 255, 255, 0);
        .operater {
          width: 20px;
          height: 20px;
          border-radius: 999px;
          border: 0.5px solid #fff;
          div {
            width: 7px;
            height: 7px;
            margin: 0 auto;
            transform: translateY(6.5px) rotate(45deg) translateX(-1.5px);
            border: 1px solid #fff;
            border-left-width: 0px;
            border-bottom-width: 0px;
          }
        }

        .operater-reverse {
          div {
            transform: translateY(6px) rotate(-135deg) translateX(-0.5px);
          }
        }
      }
    }

    .transformBoxLeft {
      transform: translateX(-100%) translateX(104px);

      .info {
        border-left: 0.5px solid rgba(255, 255, 255, 0.5);
      }
      .info-left {
        transform: translateX(-100%);
      }
    }

    .transformBoxRight {
      .info {
        border-right: 0.5px solid rgba(255, 255, 255, 0.5);
      }
      .info-right {
        transform: translateX(100%);
      }
    }
  }

  @media screen and (max-width: map-get($--ft-width, mobile)) {
    padding-bottom: 0;
    padding-top: 2rem;

    .title {
      font-size: 1.25rem;
    }

    .subTitle {
      font-size: 1rem;
      margin-bottom: 1.5rem;
      margin-top: 0.25rem;
    }

    .largeTitle {
      font-size: 1.25rem;
    }

    .middleTitle {
      margin-top: 1.31rem;
      margin-bottom: 0.25rem;
      font-size: 1rem !important;
      display: block !important;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .bottom {
      display: block;
      height: auto;

      .left-news {
        padding: 24px 12px;

        .left-top{
          max-width: unset;
        }

        .left-bottom {
          display: block;
          width: 100%;
          height: 24.434375rem;
          .news {
            .imgBox {
              width: 100%;
              max-width: 30rem;
              height: 14.5rem;
              border-radius: 0.75rem;
            }
          }
          .news-content {
            .content {
              .text-overflow {
                width: 100%;
                max-width: 100%;
                font-size: 0.75rem;
              }
              div:first-child {
                height: auto;
              }

              button {
                margin-top: 1.25rem !important;
                font-size: 0.75rem !important;
                line-height: 1.25rem;
                height: 2rem !important;
                padding: 0.4375rem 1.25rem !important;
              }
            }
          }
        }
      }
      .right-dynamic {
        padding: 1.5rem 0.75rem;

        .dynamic-background {
          background-image: none !important;
          border: none;

          .dynamic-info-box {
            display: block;
            .info-left,
            .info-right {
              width: 100%;
              min-width: auto;
            }

            .info {
              padding: 2rem;
              gap: 0.75rem;
              a {
                font-size: 0.875rem;
              }
            }

            .info-title {
              margin-bottom: 1rem;
              margin-top: 0;
            }

            .info-left {
              border: none;
              background-image: url(../../assets/img/home/dynamicBackground.png);
            }
            .info-right {
              transform: none;
              border: 0.5px solid #fff;
            }

            .position {
              margin-bottom: 1.5rem;
            }

            .position-tab {
              display: none;
            }
          }
        }
        button {
          font-size: 0.75rem !important;
          line-height: 1.25rem;
          height: 2rem !important;
          padding: 0.4375rem 1.25rem !important;
        }
      }
    }
  }
}
</style>
