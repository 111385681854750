<template>
  <div class="box" id="ftExample">
    <div class="title">非凸算法，行业客户的共同选择</div>
    <div class="examples">
      <div class="mask"></div>
      <div class="example-box">
        <div
          class="example-item"
          v-for="(item, index) in examples"
          :key="index"
        >
          <img
            class="out-side-img"
            v-lazy="item.imgOutSrc"
            alt=""
            loading="lazy"
          />
          <div class="item-outside">
            <img v-lazy="item.imgSrc" alt="" loading="lazy" />
            <div class="item-title">
              {{ item.title }}
            </div>
            <div class="item-text">
              {{ item.contents }}
            </div>
            <div
              :style="{
                'text-align': 'center',
                '--hoverBgColor': '#0F1490',
                'margin-top': 'auto',
              }"
            >
              <FtButton
                @onClick="onBtnClick"
                style="
                  width: 100%;
                  height: 40px;
                  background-color: #1c55fb;
                  border: none;
                  font-size: 16px;
                "
              >
                立即咨询
              </FtButton>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="scroll-examples">
      <div class="top">
        <!-- <div class="first"> -->
        <div
          v-for="(item, index) in [...exampleListTop, ...exampleListBottom]"
          class="item"
          :key="index"
        >
          <img v-lazy="item" alt="" loading="lazy" />
        </div>
        <!-- </div> -->
        <!-- <div class="second">
          <div
            v-for="(item, index) in exampleListTop"
            class="item"
            :key="index"
          >
            <img :src="item" alt="" loading="lazy"/>
          </div>
        </div> -->
      </div>
      <!-- <div class="bottom">
        <div class="first">
          <div
            v-for="(item, index) in exampleListBottom"
            class="item"
            :key="index"
          >
            <img :src="item" alt="" loading="lazy"/>
          </div>
        </div>
        <div class="second">
          <div
            v-for="(item, index) in exampleListBottom"
            class="item"
            :key="index"
          >
            <img :src="item" alt="" loading="lazy"/>
          </div>
        </div>
      </div> -->
    </div>
    <div :style="{ '--borderHoverColor': '#1C55FB', '--color': '#1c55fb' }">
      <FtButton
        @onClick="onBtnClick"
        style="
          border-color: #1c55fb;
          border-radius: 999px;
          height: 40px;
          width: 136px;
          padding: 7px 20px;
          margin-bottom: 72px;
          margin-top: 40px;
        "
      >
        立即咨询
      </FtButton>
    </div>
  </div>
</template>
<script>
import FtButton from "../../components/FtButton.vue";
import guorongOutside from "../../assets/img/home/example/example-guorong.png";
import taiPingYangOutside from "../../assets/img/home/example/example-taipingyang.png";
import mingShengOutSide from "../../assets/img/home/example/example-mingsheng.png";
import logoGuorong from "../../assets/img/home/example/logo-guorong.png";
import logoTaiPingYang from "../../assets/img/home/example/logo-taipingyang.png";
import logoMingSheng from "../../assets/img/home/example/logo-mingsheng.png";
import zhongxing from "../../assets/img/home/quanshang-logo/1.png";
import guotaijunan from "../../assets/img/home/quanshang-logo/2.png";
import haitong from "../../assets/img/home/quanshang-logo/3.png";
import guotou from "../../assets/img/home/quanshang-logo/4.png";
import taipingyang from "../../assets/img/home/quanshang-logo/5.png";
import fangzhen from "../../assets/img/home/quanshang-logo/6.png";
import zhongtai from "../../assets/img/home/quanshang-logo/7.png";
import dongbei from "../../assets/img/home/quanshang-logo/8.png";
import debang from "../../assets/img/home/quanshang-logo/9.png";
import caitong from "../../assets/img/home/quanshang-logo/10.png";
import huatai from "../../assets/img/home/quanshang-logo/11.png";
import yinghe from "../../assets/img/home/quanshang-logo/12.png";
import guoxing from "../../assets/img/home/quanshang-logo/13.png";
import guangfa from "../../assets/img/home/quanshang-logo/14.png";
import dongfang from "../../assets/img/home/quanshang-logo/15.png";
import huaxing from "../../assets/img/home/quanshang-logo/16.png";
import guolian from "../../assets/img/home/quanshang-logo/17.png";
// import shanghai from "../../assets/img/home/quanshang-logo/18.png";
// import guoyuan from "../../assets/img/home/quanshang-logo/19.png";
import guorong from "../../assets/img/home/quanshang-logo/20.png";

export default {
  name: "Example",
  components: {
    FtButton,
  },
  data() {
    return {
      exampleListTop: [
        zhongxing,
        guotaijunan,
        haitong,
        guotou,
        taipingyang,
        fangzhen,
        zhongtai,
        dongbei,
        debang,
        caitong,
      ],
      exampleListBottom: [
        huatai,
        yinghe,
        guoxing,
        guangfa,
        guorong,
        dongfang,
        huaxing,
        // shanghai,
        guolian,
        // guoyuan,
      ],
      examples: [
        {
          imgSrc: logoGuorong,
          imgOutSrc: guorongOutside,
          title: "底仓增强算法上线国融证券APP",
          contents:
            "非凸科技联合国融证券，突破技术卡点，显著提升交易智能化能力，为投资者带来更加高效、便捷、稳定的交易体验。",
        },
        {
          imgSrc: logoTaiPingYang,
          imgOutSrc: taiPingYangOutside,
          title: "底仓增强算法上线太平洋证券APP",
          contents:
            "非凸科技携手太平洋证券，探索金融终端解决方案，丰富算法生态应用版图，为投资者提供更加便捷且普惠的智能交易工具。",
        },
        {
          imgSrc: logoMingSheng,
          imgOutSrc: mingShengOutSide,
          title: "底仓增强算法全面上线民生证券APP",
          contents:
            "非凸科技将通过高性能交易、高效率风控、高质量服务，与民生证券携手拓展数智交易共赢新格局。",
        },
      ],
    };
  },

  methods: {
    onBtnClick() {
      this.$router.push({
        path: "/business",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  background-color: #f3f6fb;
  color: #1d2129;

  .title {
    margin: 0 auto;
    margin-top: 72px;
    margin-bottom: 44px;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
  }

  .examples {
    margin: 0 auto;
    margin-bottom: 44px;

    .mask {
      display: none;
    }

    .example-box {
      display: flex;
      gap: 48px;
      flex-wrap: wrap;
      @media screen and (max-width: 1272px) {
        gap: 10px;
        padding: 0 20px;
      }

      .example-item {
        width: 380px;
        height: 428px;
        background-color: #00000020;
        overflow: hidden;
        border-radius: 12px;

        .out-side-img {
          width: 100%;
          height: 240px;
        }
        .item-outside {
          padding: 24px;
          background-color: #fff;
          width: 100%;
          height: 100%;
          text-align: left;
          display: flex;
          flex-direction: column;
          transform: translateY(-70px);
          border-radius: 12px;
          transition: all 0.3s ease;
          cursor: pointer;

          &:hover {
            transform: translateY(-240px);
            .item-text {
              opacity: 1;
            }
          }

          img {
            height: 40px;
            width: 180px;
            object-fit: cover;
            margin-bottom: 30px;
          }

          .item-title {
            font-size: 20px;
            margin-bottom: 20px;
            font-weight: 600;
            line-height: 26px;
          }

          .item-text {
            text-align: left;
            opacity: 0;
            transition: all 0.5s ease;
            &:nth-child(3) {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .scroll-examples {
    gap: 32px;
    overflow: hidden;

    @keyframes sliding {
      0% {
        transform: translateX(0);
      }
      100% {
        transform: translateX(-100%) translateX(-32px);
      }
    }

    @mixin itemStyle() {
      width: 200px;
      height: 40px;
      line-height: 40px;
      border-radius: 4px;
      overflow: hidden;
      background-color: #fff;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .top {
      margin: 0 auto;
      display: flex;
      gap: 32px;
      width: 100%;
      max-width: 1360px;
      flex-wrap: wrap;
      justify-content: center;
      // transform: translateX(32px);
      .first,
      .second {
        display: flex;
        gap: 32px;
        // animation: 40s sliding infinite linear;
      }

      .item {
        @include itemStyle();
      }
    }
    .bottom {
      margin-top: 22px;
      display: flex;
      gap: 32px;
      transform: translateX(-108px);
      .first,
      .second {
        display: flex;
        gap: 32px;
        // animation: 40s sliding infinite linear;
        // animation-direction: reverse;
      }

      .item {
        @include itemStyle();
      }
    }
  }

  @media screen and (max-width: map-get($--ft-width, mobile)) {
    .title {
      margin: 2rem 0 1.5rem 0;
      font-size: 1.25rem;
      line-height: 1.625rem;
    }

    button {
      margin: 1.5rem 0 2rem 0 !important;
      font-size: 0.875rem !important;
      height: 2rem !important;
      width: 6rem !important;
      padding: 0 !important;
    }

    .examples {
      position: relative;
      padding: 0 0.75rem;
      flex-wrap: nowrap;
      overflow: auto;
      width: 100%;
      margin-bottom: 0;

      .mask {
        display: block;
        position: absolute;
        right: 0rem;
        width: 5.125rem;
        height: 17.5rem;
        background: linear-gradient(
          270deg,
          #f3f6fb 0%,
          rgba(243, 246, 251, 0) 100%
        );
      }

      .example-box {
        flex-wrap: nowrap;
        gap: 0.5rem;
        padding: 0;
        overflow: auto;
        &::-webkit-scrollbar {
          display: none;
        }

        .example-item {
          flex-shrink: 0;
          width: 16.5rem;
          height: 17.5rem;
          .out-side-img {
            width: 100%;
            height: 10.27rem;
          }
          .item-outside {
            padding: 0.75rem;
            transform: translateY(-2.5rem);
            img {
              height: 2rem;
              width: 9rem;
              object-fit: contain;
              margin-bottom: 1rem;
            }

            .item-title {
              font-size: 1rem;
              margin-bottom: 0.62rem;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }
            .item-text {
              height: 2.5rem;
              font-size: 0.75rem;
              line-height: 1.25rem;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }

            &:hover {
              transform: translateY(-2.5rem);
              .item-text {
                // height: auto;
                display: -webkit-box;
              }
            }
          }
        }
      }
    }
    .scroll-examples {
      display: none;
    }
  }
}
</style>
