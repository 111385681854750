var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box",attrs:{"id":"ftServe"}},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"title"},[_vm._v("丰富、成熟、专业的产品服务")]),_c('div',{staticClass:"sub-title"},[_c('router-link',{attrs:{"to":"/product/platform"}},[_vm._v("了解更多")])],1)]),_c('div',{staticClass:"center"},[_c('div',{staticClass:"left"},[_vm._l((_vm.list),function(item,index){return _c('div',{key:item.title,class:{ 'item-box': true, active: index === _vm.currentIndex },on:{"click":function($event){return _vm.onClick(index)},"mouseenter":function($event){return _vm.onMouseenter(index)}}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"sub-title"},[_vm._v(_vm._s(item.subTitle))]),_c('div',{staticClass:"img-box"},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(item.imgSrc),expression:"item.imgSrc"}],attrs:{"alt":"","loading":"lazy"}})])])}),_c('div',{style:({
          'text-align': 'center',
          'margin-top': '46px',
          padding: '0px 20px',
          '--hoverBgColor': '#0F1490',
        })},[_c('FtButton',{staticStyle:{"width":"100%","height":"40px","background-color":"#1c55fb","border":"none","font-size":"16px"},on:{"onClick":_vm.onUseBtnClick}},[_vm._v(" 立即试用 ")])],1)],2),_c('div',{staticClass:"right"},[_c('Dropdown',{staticClass:"custom-dropdown",attrs:{"optionValue":"code","optionLabel":"name","options":_vm.list.map((item, index) => {
            return { name: item.title, code: index };
          }),"placeholder":"Select a Car"},model:{value:(_vm.currentIndex),callback:function ($$v) {_vm.currentIndex=$$v},expression:"currentIndex"}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(!Array.isArray(_vm.list[_vm.currentIndex].imgSrc)),expression:"!Array.isArray(list[currentIndex].imgSrc)"},{name:"lazy",rawName:"v-lazy",value:(_vm.list[_vm.currentIndex].imgSrc),expression:"list[currentIndex].imgSrc"}],attrs:{"alt":""}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(Array.isArray(_vm.list[_vm.currentIndex].imgSrc)),expression:"Array.isArray(list[currentIndex].imgSrc)"}],staticStyle:{"position":"relative"}},[_c('div',{staticClass:"swiper-button-prev"}),_c('swiper',{attrs:{"options":_vm.swiperOptions}},_vm._l((_vm.list[_vm.currentIndex].imgSrc),function(src,index){return _c('swiper-slide',{key:index},[_c('img',{attrs:{"src":src,"alt":""}})])}),1),_c('div',{staticClass:"swiper-pagination"}),_c('div',{staticClass:"swiper-button-next"})],1),_c('div',{staticClass:"use-btn"},[_c('FtButton',{staticStyle:{"width":"100%","height":"2.5rem","background-color":"#1c55fb","border":"none","font-size":"1rem"},on:{"onClick":_vm.onUseBtnClick}},[_vm._v(" 立即试用 ")])],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }