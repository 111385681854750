<template>
  <div class="box" id="ftEcology">
    <div>
      <div class="title">非凸算法生态</div>
      <div class="sub-title">
        基于人工智能、大模型等新质生产力，结合Rust工程化应用实践，致力于为中小投资者提供一站式数智交易领域服务解决方案
      </div>
    </div>
    <div class="img-box">
      <img v-lazy="shengtaiPng" alt="" />
    </div>
  </div>
</template>

<script>
import shengtaiPng from "../../assets/img/home/生态图.png";
export default {
  name: "Ecology",
  data() {
    return {
      shengtaiPng,
    };
  },
};
</script>

<style lang="scss" scoped>
.box {
  display: flex;
  background-color: #f3f6fb;
  flex-direction: column;
  padding-top: 92px;
  padding-bottom: 72px;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
  color: #1d2129;
  // background: url(../../assets/img/home/算法生态背景.png) center no-repeat;
  // background-size:cover;

  .title {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 34px;
    margin-bottom: 8px;
  }

  .sub-title {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }
  .img-box {
    margin-top: 44px;
    img {
      width: 100%;
      max-width: 1360px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: map-get($--ft-width, mobile)) {
    padding: 2rem 0.75rem;

    .title {
      font-size: 1.25rem;
      line-height: 1.625rem;
      margin-bottom: 0.25rem;
    }
    .sub-title {
      font-size: 0.625rem;
      font-weight: 400;
      line-height: 1.125rem;
    }
    .img-box {
      margin-top: 0.5rem;
    }
  }
}
</style>
