<template>
  <div class="nav-box">
    <div
      class="item"
      id="nav-item-first"
      @click="scrollToElement('ftEcology', 0)"
    >
      非凸算法生态
    </div>
    <div class="item" @click="scrollToElement('ftServe', 1)">产品服务</div>
    <div class="item" @click="scrollToElement('ftExample', 2)">合作案例</div>
    <div class="item" @click="scrollToElement('ftAdvantages', 3)">非凸优势</div>
    <div class="item" @click="scrollToElement('ftNews', 4)">新闻动态</div>
  </div>
</template>

<script>
export default {
  name: "TopNav",
  data: function () {
    return {
      index: 0,
      elementIds: [
        "ftEcology",
        "ftServe",
        "ftExample",
        "ftAdvantages",
        "ftNews",
      ],
    };
  },
  methods: {
    scrollToElement: function (elementId, index) {
      this.index = index || 0;
      if (!elementId) {
        return;
      }
      const element = document.getElementById(elementId);
      
      if (element) {
        const scrollToHeight = element.offsetTop - 60;
        window.scrollTo({top:scrollToHeight,behavior: "smooth"})
      }
      this.sliderShow();
    },
    init: function () {
      const firstElement = document.getElementById("nav-item-first");
      this.scrollToElement(
        {
          target: {
            offsetWidth: firstElement.offsetWidth,
            offsetLeft: firstElement.offsetLeft,
          },
        },
        ""
      );
    },
    sliderShow: function (index) {
      const box = document.getElementsByClassName("nav-box")[0];
      const children = box.children;
      const _index = index || this.index;
      for (let i = 0; i < children.length; i++) {
        if (i === _index) {
          children[i].classList.add("active");
        } else {
          children[i].classList.remove("active");
        }
      }
    },
    changeFixedTop: function () {
      const swiperHeight =
        document.getElementById("banner-top")?.offsetHeight;
      const nav = document.getElementsByClassName("nav-box")?.[0];
      const navHalfHeight = nav?.offsetHeight / 2;
      const menuNav = document.querySelector(".ft-navbar");
      if (
        (window.pageXOffset || document.documentElement.scrollTop) >=
        swiperHeight - navHalfHeight
      ) {
        document
          .getElementsByClassName("nav-box")?.[0]
          ?.classList.add("nav-box-top");
        menuNav.style.visibility = "hidden";
      } else {
        document
          .getElementsByClassName("nav-box")?.[0]
          ?.classList.remove("nav-box-top");
      }
      if (
        window.pageXOffset ||
        document.documentElement.scrollTop <=
          swiperHeight - navHalfHeight - menuNav.offsetHeight
      ) {
        menuNav.style.visibility = "visible";
      }
    },
    onScrollChange: function () {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const herfElements = this.elementIds
        .map((id) => {
          return document.getElementById(id);
        })
        ?.filter((item) => item);
      for (let i = herfElements.length - 1; i >= 0; i--) {
        if (
          herfElements[i].offsetTop !== undefined &&
          scrollTop >= herfElements[i].offsetTop - 67
        ) {
          this.index = i;
          this.sliderShow(i);
          break;
        }
      }
    },
  },

  mounted: function () {
    this.init();
    this.sliderShow();
    const width = window.screen.width
    window.addEventListener("scroll", () => {
      if (width <= 600) return;
      this.changeFixedTop();
      this.onScrollChange();
    });
  },
};
</script>

<style lang="scss" scoped>
.nav-box {

  @media screen and (max-width: map-get($--ft-width, mobile)){
    display: none;
  }

  display: flex;
  position: absolute;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  max-width: 1360px;
  margin: 0 auto;
  padding: 16px 80px 0px 80px;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 8px;
  background: #fff;
  gap: 20px;
  flex-wrap: nowrap;
  overflow-y: auto;
  transition: all 0.5s;
  &::-webkit-scrollbar {
    display: none;
  }
  .item {
    cursor: pointer;
    flex-shrink: 0;

    &::after {
      content: "";
      margin-top: 14px;
      display: block;
      width: 100%;
      height: 2px;
      background-color: #1c55fb;
      transform: scaleX(0);
      transition: transform 0.5s;
    }
  }

  .active {
    color: #1c55fb;
    font-weight: 600;
    &::after {
      transform: scaleX(1);
    }
  }
}

.nav-box-top {
  position: fixed;
  justify-content: space-around;
  padding-top: 24px;
  top: 27px;
  max-width: 100%;
  z-index: 999;
  .item {
    font-size: 18px;
  }
}

@media screen and (max-width: map-get($container-max-widths, lg)) {
  .nav-box {
    max-width: 100%;
  }
}
</style>
